import React, { ReactNode } from 'react';

import {
  Avatar as AvatarUi,
  AvatarFallback as AvatarFallbackUi,
  AvatarImage,
} from '@repo/ui/components/ui/avatar';

type IAvatarProps = {
  image?: string;
  className?: string;
  text?: ReactNode;
};

export const Avatar = ({ image, text, className }: IAvatarProps) => {
  return (
    <AvatarUi className={`${className}`}>
      <AvatarImage src={image} alt={image || 'user'} />
      <AvatarFallbackUi
        className={`bg-subtle border-subtle-foreground text-subtle-foreground border capitalize`}
      >
        {text}
      </AvatarFallbackUi>
    </AvatarUi>
  );
};
