'use client';

import { useAuthStore } from '@web/lib/store/auth-store';
import React from 'react';
import { useBusinessStore } from '@web/lib/store/business-store';
import { useAppStore } from '@web/lib/store/app-store';
import { useOnMountUnsafe } from '@web/hooks/useEffectOnce';
import { businessLogic } from '@web/lib/business-logic';
import { IBusiness } from '@web/types/business';
import {
  ADMIN_EMAIL,
  MAX_BU_ADMIN_ACCOUNT,
  MAX_BU_NORMAL_ACCOUNT,
} from '@web/constants/general';

export default function MainProvider({ children }: React.PropsWithChildren) {
  const { retrieveSession } = useAuthStore();
  const { updateBusinessList } = useBusinessStore();
  const { inited } = useAppStore();

  useOnMountUnsafe(() => {
    async function getData() {
      try {
        const result = await retrieveSession();

        if (result?.id) {
          const MAX_BU =
            result?.email === ADMIN_EMAIL
              ? MAX_BU_ADMIN_ACCOUNT
              : MAX_BU_NORMAL_ACCOUNT;
          const data = await businessLogic.getMyBusinessList(result.id, MAX_BU);
          updateBusinessList(data as IBusiness[]);
        }
        inited();
      } catch (error) {
        updateBusinessList([]);
        console.error(error);
      } finally {
        inited();
      }
    }
    getData();
  });

  return <>{children}</>;
}
