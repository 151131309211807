import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/alert-modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/the-nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/providers/main-provider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/providers/query-provider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Sans_Thai\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--font-noto-sans-thai\"}],\"variableName\":\"noto_sans_thai\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}")