import { create } from 'zustand';

export type IAppState = {
  isInit: boolean;
};

export type IAppActions = {
  inited: () => void;
};

export const useAppStore = create<IAppState & IAppActions>()((set) => {
  return {
    isInit: false,
    inited: () => {
      set({ isInit: true });
    },
  };
});
