'use client';
import React, { useRef, useState } from 'react';
import Image from 'next/image';
import { Button } from '@repo/ui/components/ui/button';

import { useAuthStore } from '@web/lib/store/auth-store';

import { LoginModal } from './login-modal';

import Link from 'next/link';
import { ROUTES, USER_MENUS } from '@web/constants/route';
import { Avatar } from './avatar';
import { useBusinessStore } from '@web/lib/store/business-store';
import { useAppStore } from '@web/lib/store/app-store';
import { ArrowRight, LogOut, Menu, User } from '@repo/ui/components/ui/icons';
import { MenuItem } from '@repo/ui/components/ui/menu-item';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@repo/ui/components/ui/popover';
import { usePathname } from 'next/navigation';

export const TheNav = () => {
  const pathname = usePathname();
  const { isInit } = useAppStore();
  const { user, isAuthenticating, login, logout } = useAuthStore();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const redirectUrlRef = useRef<string | undefined>(undefined);
  const { businesses } = useBusinessStore();

  function _renderButtonContent() {
    if (isAuthenticating) return null;
    if (!user) {
      return <Button onClick={() => setShowLoginModal(true)}>Login</Button>;
    }
    if (user) {
      return (
        <Popover>
          <PopoverTrigger>
            {user.picture ? (
              <Button variant={'outline'} className="w-fit p-2">
                <Avatar
                  className="border-border scale-75 border "
                  image={user.picture}
                />
                {user.full_name?.split(' ')[0]}
              </Button>
            ) : (
              <Avatar
                className="border-border scale-75 border "
                text={<User size={24} strokeWidth={2} />}
              />
            )}
          </PopoverTrigger>
          <PopoverContent align="end" className="w-60">
            {USER_MENUS.map(({ label, to }) => (
              <Link href={to} key={to}>
                <MenuItem>
                  <p>{label}</p>
                </MenuItem>
              </Link>
            ))}
            <MenuItem
              onClick={logout}
              className="border-border cursor-pointer border-t"
            >
              <LogOut size={16} />
              <p>Logout</p>
            </MenuItem>
          </PopoverContent>
        </Popover>
      );
    }
    return null;
  }

  function _renderBurgerMenu() {
    if (isAuthenticating) return null;
    if (!user) {
      return <Button onClick={() => setShowLoginModal(true)}>Login</Button>;
    }
    if (user) {
      return (
        <Popover>
          <PopoverTrigger>
            <Button
              size="sm"
              variant="outline"
              className="flex gap-x-2 transition-all"
            >
              <Menu size={16} strokeWidth={1.5} />
              Menu
            </Button>
          </PopoverTrigger>
          <PopoverContent align="end" className="w-60">
            <Link href={ROUTES.BUSINESSES} target="_blank">
              <MenuItem>Switch to Business mode</MenuItem>
            </Link>
            {USER_MENUS.map(({ label, to }) => (
              <Link href={to} key={to}>
                <MenuItem>
                  <p>{label}</p>
                </MenuItem>
              </Link>
            ))}
            <MenuItem
              onClick={logout}
              className="border-border cursor-pointer border-t"
            >
              <LogOut size={16} />
              <p>Logout</p>
            </MenuItem>
          </PopoverContent>
        </Popover>
      );
    }
    return null;
  }

  function _renderBusinessButton() {
    if (!isInit) return null;
    if (!user) {
      return (
        <Button
          variant="outline"
          onClick={() => {
            redirectUrlRef.current = ROUTES.BUSINESS_CREATOR_GET_START;
            setShowLoginModal(true);
          }}
        >
          Join Petcation Business
        </Button>
      );
    }

    if (!pathname.includes('business')) {
      return (
        <Link href={ROUTES.BUSINESS_CREATOR_GET_START} target="_blank">
          <Button variant="outline">Create new business</Button>
        </Link>
      );
    }
  }
  function _renderBusinessModeButton() {
    if (!isInit) return null;

    if (
      !pathname.includes('/business-detail/') &&
      pathname.includes('business')
    ) {
      return <></>;
    } else {
      return (
        <div>
          <Link href={ROUTES.BUSINESSES} target="_blank">
            <Button variant="secondary">Switch to Business mode</Button>
          </Link>
        </div>
      );
    }
  }

  function _renderMobileCreateNewBusinessButton() {
    if (isAuthenticating || user) return null;

    return (
      <div className="bg-info flex h-[41px] w-full items-center justify-center md:hidden">
        <button
          className="flex items-center gap-x-2"
          onClick={() => {
            redirectUrlRef.current = ROUTES.BUSINESS_CREATOR_GET_START;
            setShowLoginModal(true);
          }}
        >
          <span className="text-info-foreground">Create new business</span>
          <ArrowRight size={18} className="text-info-foreground" />
        </button>
      </div>
    );
  }

  return (
    <>
      <nav className="border-base sticky top-0 z-20 h-20 w-full border-b bg-white">
        <div className=" flex h-full items-center justify-between px-10">
          <div className="flex w-1/3 items-end gap-6">
            <div>
              <Link href={ROUTES.HOME}>
                <div className="relative h-8 w-28">
                  <Image
                    fill
                    src={'/images/logo.svg'}
                    alt={'Petcation logo'}
                    sizes="(max-width: 120px)"
                  />
                </div>
              </Link>
              {!pathname.includes('/business-detail/') &&
              pathname.includes('business') ? (
                <div className="md:hidden">
                  <Link href={ROUTES.BUSINESSES}>
                    <Button variant="ghost" size={'none'}>
                      Business center
                    </Button>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </div>

            {!pathname.includes('/business-detail/') &&
            pathname.includes('business') ? (
              <div className="hidden md:block">
                <Link href={ROUTES.BUSINESSES}>
                  <Button variant="ghost" size={'none'}>
                    Business center
                  </Button>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className=" hidden w-full justify-end  gap-4 md:flex">
            {businesses.length > 0 ? (
              <>{_renderBusinessModeButton()}</>
            ) : (
              <>{_renderBusinessButton()}</>
            )}
            {_renderButtonContent()}
          </div>

          <div className=" flex items-center gap-x-3 md:hidden">
            {_renderBurgerMenu()}
          </div>
        </div>
        {_renderMobileCreateNewBusinessButton()}
      </nav>

      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={(val) => {
          // close modal
          setShowLoginModal(val);
          redirectUrlRef.current = undefined;
        }}
        onLogin={() => {
          login(redirectUrlRef.current);
        }}
      />
    </>
  );
};
