import React from 'react';
import { Button } from '@repo/ui/components/ui/button';
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogDescription,
  DialogFooter,
  DialogContentWithCustomClose,
} from '@repo/ui/components/ui/dialog';
import Image from 'next/image';

type ILoginModalProps = {
  showLoginModal: boolean;
  setShowLoginModal: (val: boolean) => void;
  onLogin: () => void;
};

export const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  onLogin,
}: ILoginModalProps) => {
  return (
    <>
      <Dialog
        open={showLoginModal}
        onOpenChange={(val) => {
          if (!val) {
            setShowLoginModal(false);
          }
        }}
      >
        <DialogContentWithCustomClose className="w-11/12 rounded-[0.75rem] p-10 ">
          <div className="flex items-center justify-between">
            <DialogTitle className="text-left text-xl">
              Log in or sign up
            </DialogTitle>
            <Button
              className="h-[34px] w-[42px] rounded-[18px]"
              variant="secondary"
              onClick={() => setShowLoginModal(false)}
            >
              <Image
                src="/icons/times.svg"
                width={18}
                height={18}
                className="min-h-[18px] min-w-[18px]"
                alt={'times'}
              />
              <span className="sr-only">Close</span>
            </Button>
          </div>
          <DialogHeader>
            <Image
              src="/images/login.png"
              className="mx-auto h-auto w-full"
              width={358}
              height={260}
              alt={'login'}
            />
            <Button
              className="mx-auto flex w-[192px] gap-x-2"
              variant="outline"
              onClick={onLogin}
            >
              <div className="h-[18px] w-[18px] overflow-hidden rounded-full">
                <Image
                  src={'/icons/Logo-google.svg'}
                  alt={'logo google'}
                  width={18}
                  height={18}
                />
              </div>
              <span>Sign in with google</span>
            </Button>
          </DialogHeader>
          <DialogFooter>
            <DialogDescription className="pt-2 text-center">
              By signing up or logging in, you acknowledge and agree to
              Petcation's General Terms of Use and Privacy Policy
            </DialogDescription>
          </DialogFooter>
        </DialogContentWithCustomClose>
      </Dialog>
    </>
  );
};
