import { create } from 'zustand';

export type IAlertModalState = {
  title: string;
  message: string;
  okText?: string;
  noText?: string;
  imgUrl: string;
  isOpen: boolean;
};

export type IAlertModalActions = {
  open: (value: IAlertModalState) => void;
  close: () => void;
};

export const useAlertModalStore = create<
  IAlertModalState & IAlertModalActions
>()((set) => {
  return {
    title: '',
    message: '',
    okText: '',
    noText: '',
    imgUrl: '',
    isOpen: false,
    open(value) {
      set(() => {
        return {
          ...value,
          isOpen: true,
        };
      });
    },
    close() {
      set(() => {
        return {
          isOpen: false,
        };
      });
    },
  };
});
