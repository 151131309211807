'use client';
import { FOOTER_MENUS } from '@web/constants/route';
import Link from 'next/link';
import React from 'react';

export const Footer = () => {
  return (
    <footer className="border-border text-muted-foreground text-b4 flex flex-col justify-between border-t px-14 py-7 lg:flex-row">
      ©2024, Petcation. All Rights Reserved.
      <div className="flex space-x-4">
        {FOOTER_MENUS.map(({ label, to }) => (
          <Link href={to} key={to} className="hover:opacity-hover">
            <p>{label}</p>
          </Link>
        ))}
      </div>
    </footer>
  );
};
