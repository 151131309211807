import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';

const menuItemVariants = cva(
  'icon-wrapper hover:opacity-hover px-6 py-3 text-b4  ',
  {
    variants: {
      variant: {
        default: 'hover:bg-secondary ',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface MenuItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof menuItemVariants> {
  className?: string;
}
function MenuItem({ variant, children, className, ...props }: MenuItemProps) {
  return (
    <div className={cn(menuItemVariants({ className, variant }))} {...props}>
      {children}
    </div>
  );
}

export { MenuItem, menuItemVariants };
