import { create } from 'zustand';
import { IBusiness } from '@web/types/business';

export type IBusinessState = {
  isFetching: boolean;
  businesses: IBusiness[];
  business: IBusiness | null;
  businessById: string | null;
};

export type IBusinessStateActions = {
  updateBusinessDetail: (value: IBusiness | null) => void;
  updateBusinessList: (value: IBusiness[]) => void;
};

export const useBusinessStore = create<
  IBusinessState & IBusinessStateActions
>()((set) => {
  return {
    isNew: true,
    isFetching: true,
    business: null,
    businesses: [],
    businessById: null,
    tempFiles: [],
    updateBusinessList: (value: IBusiness[]) => {
      set(() => {
        return {
          businesses: value,
        };
      });
    },
    updateBusinessDetail: (value: IBusiness | null) => {
      set(() => {
        return {
          business: value,
        };
      });
    },
  };
});
