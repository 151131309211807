"use client";
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@repo/ui/components/ui/dialog";
import { useAlertModalStore } from "@web/lib/store/alert-modal-store";
import Image from "next/image";
import { Button } from "@repo/ui/components/ui/button";
export const AlertModal = () => {
  const { isOpen, title, okText, message, imgUrl, close } =
    useAlertModalStore();

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(val) => {
          if (!val) {
            close();
          }
        }}
      >
        <DialogContent className="w-[384px]">
          <DialogHeader>
            <Image
              className="m-auto"
              src={imgUrl}
              alt={title}
              width={120}
              height={120}
            />

            <DialogTitle className="pt-5 text-center">{title}</DialogTitle>
            <DialogDescription className="pt-2 text-center">
              {message}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button className="w-full" variant="default" onClick={close}>
              {okText}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
